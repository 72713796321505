var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[((_vm.config?.type ?? 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelInput),expression:"modelInput"}],staticClass:"form-control",class:{
                [_vm.config.classCustom]: _vm.config.classCustom,
                'is-invalid': _vm.config?.error ?? false,
                disabled_cursor: _vm.disabled
            },attrs:{"id":_vm.id,"placeholder":_vm.config?.placeholder ?? '',"name":_vm.config?.name ?? '',"maxlength":_vm.config?.maxlength ?? null,"min":_vm.config?.min ?? '',"max":_vm.config?.max ?? '',"multiple":_vm.config?.multiple ?? false,"autofocus":_vm.config?.autofocus ?? false,"disabled":_vm.disabled,"autocomplete":_vm.config?.autocomplete ?? 'off',"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.modelInput)?_vm._i(_vm.modelInput,null)>-1:(_vm.modelInput)},on:{"input":_vm.handleInput,"change":[function($event){var $$a=_vm.modelInput,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modelInput=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modelInput=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modelInput=$$c}},_vm.handleChangeInput],"keypress":_vm.handleKeyPress,"blur":_vm.handleBlur,"paste":_vm.handlePaste}}):((_vm.config?.type ?? 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelInput),expression:"modelInput"}],staticClass:"form-control",class:{
                [_vm.config.classCustom]: _vm.config.classCustom,
                'is-invalid': _vm.config?.error ?? false,
                disabled_cursor: _vm.disabled
            },attrs:{"id":_vm.id,"placeholder":_vm.config?.placeholder ?? '',"name":_vm.config?.name ?? '',"maxlength":_vm.config?.maxlength ?? null,"min":_vm.config?.min ?? '',"max":_vm.config?.max ?? '',"multiple":_vm.config?.multiple ?? false,"autofocus":_vm.config?.autofocus ?? false,"disabled":_vm.disabled,"autocomplete":_vm.config?.autocomplete ?? 'off',"type":"radio"},domProps:{"checked":_vm._q(_vm.modelInput,null)},on:{"input":_vm.handleInput,"change":[function($event){_vm.modelInput=null},_vm.handleChangeInput],"keypress":_vm.handleKeyPress,"blur":_vm.handleBlur,"paste":_vm.handlePaste}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelInput),expression:"modelInput"}],staticClass:"form-control",class:{
                [_vm.config.classCustom]: _vm.config.classCustom,
                'is-invalid': _vm.config?.error ?? false,
                disabled_cursor: _vm.disabled
            },attrs:{"id":_vm.id,"placeholder":_vm.config?.placeholder ?? '',"name":_vm.config?.name ?? '',"maxlength":_vm.config?.maxlength ?? null,"min":_vm.config?.min ?? '',"max":_vm.config?.max ?? '',"multiple":_vm.config?.multiple ?? false,"autofocus":_vm.config?.autofocus ?? false,"disabled":_vm.disabled,"autocomplete":_vm.config?.autocomplete ?? 'off',"type":_vm.config?.type ?? 'text'},domProps:{"value":(_vm.modelInput)},on:{"input":[function($event){if($event.target.composing)return;_vm.modelInput=$event.target.value},_vm.handleInput],"change":_vm.handleChangeInput,"keypress":_vm.handleKeyPress,"blur":_vm.handleBlur,"paste":_vm.handlePaste}})]),(_vm.config.error)?_c('div',[_c('MessageInput',{attrs:{"configMessage":_vm.config}})],1):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }