<template>
    <div>
        <div>
            <input
                :id="id"
                v-model="modelInput"
                :type="config?.type ?? 'text'"
                :placeholder="config?.placeholder ?? ''"
                class="form-control"
                :class="{
                    [config.classCustom]: config.classCustom,
                    'is-invalid': config?.error ?? false,
                    disabled_cursor: disabled
                }"
                :name="config?.name ?? ''"
                :maxlength="config?.maxlength ?? null"
                :min="config?.min ?? ''"
                :max="config?.max ?? ''"
                :multiple="config?.multiple ?? false"
                :autofocus="config?.autofocus ?? false"
                :disabled="disabled"
                :autocomplete="config?.autocomplete ?? 'off'"
                @input="handleInput"
                @change="handleChangeInput"
                @keypress="handleKeyPress"
                @blur="handleBlur"
                @paste="handlePaste"
            />
        </div>
        <div v-if="config.error">
            <MessageInput :configMessage="config" />
        </div>
    </div>
</template>

<script>
import { isNumber } from '@/utils/format';

import MessageInput from './message.vue';

export default {
    name: 'InputCommon',
    components: {
        MessageInput
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'input'
        },
        model: {
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    classCustom: 'form-control',
                    name: '',
                    maxlength: null,
                    type: 'text',
                    placeholder: '',
                    min: '',
                    max: '',
                    isRequired: false,
                    isMaxLength: false,
                    isKatakana: false,
                    isUrl: false,
                    isEmail: false,
                    isNumber: false,
                    isPassword: false,
                    multiple: false,
                    autofocus: false,
                    error: false,
                    errorCustomMess: '',
                    valueSync: '',
                    typesValidate: []
                };
            }
        }
    },
    data() {
        return {
            modelInput: ''
        };
    },
    watch: {
        '$props.model': {
            handler: function (newValue) {
                let config = this.config;
                config.valueSync = newValue;
                this.modelInput = newValue;
            },
            immediate: true
        },

        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        },

        modelInput: {
            handler: function (newModelInput) {
                let config = this.config;
                config.error = false;
                config.isRequired = false;
                config.isMaxLength = false;
                config.isKatakana = false;
                config.isUrl = false;
                config.isEmail = false;
                config.isPassword = false;
                config.errorCustomMess = '';
                config.valueSync = newModelInput;
                this.$emit('update:model', newModelInput);
                this.$emit('update:config', config);
            }
        }
    },
    methods: {
        handleInput() {
            this.$emit('update:model', this.modelInput);
            this.$emit('input', this.modelInput);
        },
        handleChangeInput() {
            this.$emit('update:model', this.modelInput);
            this.$emit('change', this.modelInput);
        },
        handleKeyPress(event) {
            let config = this.config;
            if (config.isNumber) {
                isNumber(event);
            }
            this.$emit('update:model', this.modelInput);
            this.$emit('keypress', this.modelInput);
        },
        handleBlur() {
            this.$emit('blur');
        },
        handlePaste(event) {
            this.$emit('paste');
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
            if (!/^\d+$/.test(pastedData) && this.config.isNumber) {
                event.preventDefault();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
